import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import Modal from '@core/components/modal'
import './global-components'
import axios from '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'sweetalert2/dist/sweetalert2.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { VueGoodTable } from 'vue-good-table'

import vSelect from 'vue-select'
import draggable from 'vuedraggable'

import VueElementLoading from 'vue-element-loading'
import MainLoader from '@core/components/loader/MainLoader.vue'
import Cleave from 'vue-cleave-component'

import VueViewer from 'v-viewer'

import { QueryBuilderPlugin } from '@syncfusion/ej2-vue-querybuilder'
import '@syncfusion/ej2-base/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-splitbuttons/styles/material.css'
import '@syncfusion/ej2-dropdowns/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-lists/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'
import '@syncfusion/ej2-calendars/styles/material.css'
import '@syncfusion/ej2-vue-querybuilder/styles/material.css'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

import VueCookies from 'vue-cookies'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import VueConfetti from 'vue-confetti'

import router from './router'
import App from './App.vue'
import store from './store'
import 'flatpickr/dist/flatpickr.css'

Pusher.logToConsole = false
window.Pusher = Pusher
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    disableStats: true,
    debug: true,
    auth: {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Or however you store your token
            Accept: 'application/json',
        },
    },
})

library.add(fas, far, fab)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('VueGoodTable', VueGoodTable)
Vue.component('VSelect', vSelect)
Vue.component('Draggable', draggable)
Vue.component('VueElementLoading', VueElementLoading)
Vue.component('MainLoader', MainLoader)
Vue.component('Cleave', Cleave)
Vue.use(VueViewer)
Vue.use(Modal)
Vue.use(QueryBuilderPlugin)
const { t } = useI18nUtils()
Vue.prototype.$axios = axios
axios.defaults.baseURL = window.location.origin
Vue.prototype.$baseUrl = axios.defaults.baseURL
Vue.config.productionTip = false
Vue.use(VueCookies)
if (window.location.host !== 'dev.connect.local') {
    Vue.$cookies.config('', '', '', true, 'None')
}

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VueGoodTable)
Vue.use(VueConfetti)
require('@core/assets/fonts/feather/iconfont.css')
require('@resources/scss/core.scss')
require('@resources/assets/scss/style.scss')
require('@resources/scss/vue/libs/vue-good-table.scss')
require('@resources/scss/vue/libs/vue-select.scss')

new Vue({
    router,
    store,
    i18n,
    t,
    render: h => h(App),
}).$mount('#app')
